export const contentTableConfig = {
  title: '',
  // title: ('common.app-terminal-online-list'),
  propList: [
    {
      prop: 'extend1',
      label: 'monitor.ota-name'
    },
    {
      prop: 'extend2',
      label: 'file.version-code'
    },
    {
      prop: 'successCount',
      label: 'monitor.success-count'
    },
    {
      prop: 'failedCount',
      label: 'monitor.failed-count'
    },
    {
      prop: 'pushedCount',
      label: 'monitor.pushed-count'
    },
    {
      prop: 'updatedBy',
      label: 'general.operator'
    },
    {
      prop: 'createdTime',
      slotName: 'formatZeroTime',
      width: 160,
      label: 'general.operation-time'
    },
    {
      label: 'common.app-operation',
      width: 105,
      slotName: 'handler'
    }
  ],
  showIndexColumn: true,
  showSelectColumn: false
}
